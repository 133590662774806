import React, { Component, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {Row, Col, Spinner} from "react-bootstrap";
import '../fonts/TradeGothic.otf';
import Modal from "react-bootstrap/Modal";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Markdown from 'markdown-to-jsx';
import Pagination from "react-bootstrap/Pagination"
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

class Candidate extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.selectEmail= this.selectEmail.bind(this);
    this.state = {
      error: null,
      isLoaded: false,
      candidates: [],
      selectedItems: [],
      name: "",
      template: "",
      showEditor: false,
      showTemplate: true,
      email: "",
      Subject:"",
      candidateEmails: [],
      selectedEmail: 'Select email',
      emailSubject:"",
      filter:"",
      currentPage: 1,
      cardsPerPage: 10,
      current_user: this.props.currentUser,
      allTags: [],
      optionSelect: 'All',
      showSpinner:false
      }  
      this.handleClick = this.handleClick.bind(this);
      this.handleClickPrevPage = this.handleClickPrevPage.bind(this);
      this.handleClickNextPage = this.handleClickNextPage.bind(this);
    };
  
	handleClose() {
		this.setState({ show: false });
  }
  
  handleClickPrevPage(event) {
    this.setState({
      currentPage: this.state.currentPage - 1
    });
  }
  handleClickNextPage(event) {
    this.setState({
      currentPage: this.state.currentPage + 1
    });
  }
  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id)
    });
  }

  fetchFiltered = (option='All') => {
	  fetch(`${process.env.REACT_APP_BACKEND_URL}/recruitment`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ Email: this.props.currentUser.email, OptionSelect: option })
    })
      .then(res => res.json())
      .then((result) => {
        console.log(result)
          result[2].unshift('All');
          this.setState({
            isLoaded: true,
            candidates: result[0],
            template: result[1].Template,
            Subject: result[1].Subject,
            emailSubject: result[1].Subject,
            allTags: result[2],
            showSpinner: false,
            currentPage: 1
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidMount() {
    this.fetchFiltered();
  }


  callFetchFiletered = e =>  {
    this.setState({optionSelect: e, showSpinner:true});
    this.fetchFiltered(e);
  }

  getModal = e => {
    this.setState({ 
      show: true, 
      name: e.target.id,
      candidateEmails: e.target.value.split(","), 
      emailSubject: this.state.Subject,
      email: "Hi" + " " + e.target.id+","+ this.state.template
    });
  };

  boxClick = (e) => {
    let { selectedItems } = this.state;
    if (selectedItems.includes(e.target.id)){
      this.setState({ show: false });
      return selectedItems;
    }
    e.preventDefault();
    if (this.state.selectedEmail === "Select email") {
      alert("Please select a candidate email");
    } 
    else {
      selectedItems.push(e.target.id);
      this.setState({ show: false, 
                      selectedEmail: 'Select email',
                      emailSubject: this.state.Subject });
      var button = document.getElementsByTagName("Button")[e.target.id];
      button.style.backgroundColor='#FBB100';
      button.innerHTML='Sent Email';
      button.disabled=true;
      return fetch(`${process.env.REACT_APP_BACKEND_URL}/recruitment`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }, 
        method: 'POST',
        body: JSON.stringify({ Candidate: e.target.id, Template: e.target.value, Email: this.props.currentUser.email, Subject: this.state.emailSubject, CandidateEmail: this.state.selectedEmail })
      });
   }
  }

  showEditor = e => {
    this.setState({
      showEditor: true,
      showTemplate: false,
    });
  };

  save = e => {
    this.setState({
      showEditor: false,
      showTemplate: true,
    });
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/update_template`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }, 
      method: 'POST',
      body: JSON.stringify({ Subject:this.state.Subject, Email: this.props.currentUser.email, Template: this.state.template})
    });
  };

  selectEmail = e => {
    this.setState({
      selectedEmail: e.target.value
    });
  };

  handleChange = event => {
    this.setState({ filter: event.target.value, currentPage: 1});
  };


  render() {
    const { showEditor, showTemplate, template, Subject} = this.state;
    const { candidates, currentPage, cardsPerPage, filter} = this.state;
    const lowercasedFilter = filter.toLowerCase();
    const filteredData = candidates.filter(item => {
      return ["Name", "Title", "Company"].some(key =>
        item[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const pageItems =  filteredData.slice(indexOfFirstCard, indexOfLastCard);
    const renderCandidates =  pageItems.map(candidate => {
      return( 
        <Container className="border rounded" >
        <Row style={{padding:'15px'}}>
          <Col sm="auto" md="auto" lg="2" className="row-align text-left"><a href={candidate.Linkedin}>{candidate.Name}</a></Col>
          <Col sm="auto" md="auto" lg="2" className="row-align text-left">{candidate.Title}</Col>
          <Col sm="auto" md="auto" lg="2" className="row-align text-left">{candidate.Company}</Col>
          <Col sm="auto" md="auto" lg="3" className="row-align text-left">
          {candidate.Email}
          </Col>
          <Col sm="auto" md="auto" lg="2" className="row-align text-right">
            <Button 
              id={candidate.Name} 
              value={candidate.Email}
              style={{backgroundColor: candidate.bgColor, border: 'none'}} 
              onClick={e => this.getModal(e)}
              disabled={candidate.isDisabled}
            >
              {candidate.buttonText}
            </Button>
          </Col>
        </Row>
      </Container>
      );
      });

      const pageNumbers = [];
      let max_page = Math.ceil(filteredData.length / cardsPerPage);
      let end_page = max_page < this.state.currentPage + 5 ? max_page: this.state.currentPage + 5;
      let end_page_last = end_page === this.state.currentPage;
      let start_page = max_page - 5 < this.state.currentPage && max_page - 5 > 0? max_page - 5 : this.state.currentPage;
      let start_page_first = this.state.currentPage === 1;
      for (let i = start_page; i <= end_page; i++) {
        pageNumbers.push(i);
      }
      const renderPageNumbers = pageNumbers.map(number => {
        return (
          <Pagination.Item
           className={(this.state.currentPage === number ? 'active ' : '') + 'controls'}
            key={number}
            id={number}
            onClick={this.handleClick} >
            {number}
          </Pagination.Item>
        );
      });
      if (!start_page_first)
      {
        let first = <Pagination.Prev
           className={'controls'}
            onClick={this.handleClickPrevPage} >
          </Pagination.Prev>
          renderPageNumbers.unshift(first)
      }
      if (!end_page_last)
      {
        let last = <Pagination.Next
           className={'controls'}
            onClick={this.handleClickNextPage} >
          </Pagination.Next>
          renderPageNumbers.push(last)
      }

    return (
    <React.Fragment>
    <Container>
      <h2>Hypergrowth Recruitment Network</h2>
            <Row style={{marginBottom:'60px', marginTop:'20px'}}>
              <Col>
               <Container style={{width: '73%'}}>
                <div className='text-left' style={{paddingBottom:'5px'}}> 
                    <Button disabled = {this.state.showEditor} onClick={e => this.showEditor(e)} style={{backgroundColor: "#1c0c74", marginRight:'10px'}} >Edit</Button>
                </div>
                </Container>
                <Container style={{width: '70%', backgroundColor: '#EAE5FF', padding: '50px', borderRadius:'3px'}}>
                  <p className="text-muted text-left">Customize the baseline template that will be used to reach out to candidates in our tool! You will still be able to edit the message for each candidate before sending.</p>
                  <h5 className="text-left">Edit baseline subject line:</h5>
                  <Form.Control disabled={showTemplate} id="Subject" defaultValue={Subject} onChange={event => this.setState({Subject: event.target.value})}/>
                  <h5 className="text-left">Edit baseline reach out template:</h5>
              
                { showTemplate
                    ?
                    <Container className="border rounded text-left" style={{backgroundColor: 'white', marginBottom:'10px'}}>
                      <Markdown>{template}</Markdown>
                  </Container>
                    : null
                }
                { showEditor
                    ?  <div className="border rounded text-left" style={{marginBottom:'10px'}}>
                      <CKEditor
                        editor={ ClassicEditor }
                        id={this.state.name}
                        data={this.state.template}
                        onReady={ editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            this.setState({template: data})
                            console.log( { event, editor, data } );
                        } }
                        onBlur={ ( event, editor ) => {
                            console.log( 'Blur.', editor );
                        } }
                        onFocus={ ( event, editor ) => {
                            console.log( 'Focus.', editor );
                        } }
                      />
                      <div className='text-center'>
                      <Button onClick={e => this.save(e)} style={{backgroundColor: "#EE00FF"}}>Save</Button>
                      </div>
                      </div>
                    : null
                }
                </Container>
              </Col>
          </Row>
          <p className="text-muted text-left"><b>Note:</b> we are using Sendgrid to send and track these emails, so "sent via sendgrid.net" will show up next to your 'from' email address.</p>
          <p className="text-muted text-left">If you want to remove this, contact us to authenticate your domain.</p>
      <p style={{fontFamily:'TradeGothic'}}></p>
      <div>

               <DropdownButton variant="secondary" title={this.state.optionSelect} onSelect={e => this.callFetchFiletered(e)}>
                {this.state.allTags.map(tag => {
                  return <Dropdown.Item eventKey={tag}>{tag}</Dropdown.Item>
                })}
            </DropdownButton>
               <p></p>
               <p>{this.state.value}</p>
              { this.state.showSpinner ? <Spinner animation='border'/> : null}
      </div>
      <React.Fragment><Col className="w-50 mx-auto" style={{marginBottom: '40px'}}><Row><Form.Label>Search for a candidate by company name or title:</Form.Label></Row><Row><Form.Control value={filter} onChange={this.handleChange} /></Row></Col></React.Fragment>
      <Container style={{paddingBottom:'50px'}}>
        <Row className="border-bottom" style={{padding:'15px', paddingBottom:'20px'}}>
            <Col sm="2" md="2"  className="text-left"><b>Candidate Name</b></Col>
            <Col sm="2" md="2"  className="text-left"><b>Title</b></Col>
            <Col sm="2" md="2"  className="text-left"><b>Company</b></Col>
            <Col sm="2" md="2" className="text-left"><b>Email(s)</b></Col>
        </Row>
        <Row>
          {renderCandidates}
          <div id="page-numbers" style={{marginTop:'10px'}}>
            <Pagination>
              {renderPageNumbers}
          </Pagination>
          </div>
        </Row>
      </Container>
        
				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Auto Reach Out</Modal.Title>
					</Modal.Header>
					  <Modal.Body> 
            <DropdownButton variant="secondary" title={this.state.selectedEmail} onSelect={event => this.setState({selectedEmail: event})}>
                {this.state.candidateEmails.map(email => {
                  return <Dropdown.Item eventKey={email}>{email}</Dropdown.Item>
                })}
            </DropdownButton>
            <br></br>
            <b>Subject: </b>
            <Form.Control id="Subject" defaultValue={this.state.emailSubject} onChange={event => this.setState({emailSubject: event.target.value})}/>  
            <br></br>   
            <b>Email Body: </b>
              <CKEditor
                      editor={ ClassicEditor }
                      id={this.state.name}
                      data={this.state.email}
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          this.setState({email: data})
                          console.log( { event, editor, data } );
                      } }
                      onBlur={ ( event, editor ) => {
                          console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          console.log( 'Focus.', editor );
                      } }
                  />
            </Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
            </Button>
						<Button variant="primary" style={{backgroundColor: "#1c0c74"}} id={this.state.name}  value={this.state.email} onClick={e => this.boxClick(e)}>
              Send Email
            </Button>
					</Modal.Footer>
				</Modal>
      </Container>
      </React.Fragment>
    );
  }
}
 
export default Candidate;