import React, {Component} from "react";
import Pagination from "react-bootstrap/Pagination"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import {Row, Col, Tabs, Tab} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import dropdown from "../Chevron.png";
import Markdown from "markdown-to-jsx";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Modal from "react-bootstrap/Modal";

class MentorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listitems: [],
            currentPage: 1,
            cardsPerPage: 12,
            current_user: this.props.currentUser,
            filter: "",
            categoryFilter: "All Categories",
            fundingStageFilter: "All Funding Stages",
            totalFundingFilter: "Any Funding",
            isPortfolio: false,
            showLimitModal: false,
            requestLogs: [],
            allCategories: [],
            allFundingStages: [],
            allFundingRanges: ['Any Funding', '0-1M', '1M-10M', '10M-100M', '100M-1B', '1B+', 'Public']
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/mentors`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({Email: this.props.currentUser.email})
        })
            .then(res => res.json())
            .then((result) => {
                    console.log(result)
                    this.setState({
                        isLoaded: true,
                        listitems: result[0],
                        isPortfolio: result[1],
                        requestLogs: result[2],
                        allCategories: result[3],
                        allFundingStages: result[4],
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    handleClick(event) {
        this.setState({
            currentPage: Number(event.target.id)
        });
    }

    handleClose(event) {
        this.setState({showLimitModal: false});
    }
    boxClick = (e) => {
        console.log(e.target.id)
        e.preventDefault();
        if (e.target.value === "") {
            alert("Please enter a message for this advisor before requesting an introduction");
        } else {
            //console.log(this.state.listitems[e.target.id].Name);
            const newList = this.state.listitems.slice() //copy the array
            newList[e.target.id].bgColor = '#FBB100' //execute the manipulations
            newList[e.target.id].buttonText = 'Requested' //execute the manipulations
            newList[e.target.id].isDisabled = true //execute the manipulations
            newList[e.target.id].Message = e.target.value
            this.setState({listitems: newList}) //set the new state
            fetch(`${process.env.REACT_APP_BACKEND_URL}/mentor_request`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    Email: this.props.currentUser.email,
                    Customer: this.state.listitems[e.target.id].Name,
                    Message: this.state.listitems[e.target.id].Message
                })
            })
                .then(res => res.json())
                .then((result) => {
                    this.setState({requestLogs: result['mentors_sent_rows'], showLimitModal: result['request_exceed']})
                });
        }
    }
    updateValue = (e) => {
        let new_list = this.state.listitems.slice()
        new_list[e.target.name]['Message'] = e.target.value
        this.setState({listitems: new_list})
    }

    handleSearchChange = event => {
        this.setState({
            filter: event.target.value, categoryFilter: 'All Categories',
            fundingStageFilter: 'All Funding Stages',
            totalFundingFilter: 'Any Funding',
            currentPage: 1
        });
    };

    handleCategoryChange = event => {
        this.setState({
            categoryFilter: event, filter: '', fundingStageFilter: 'All Funding Stages',
            totalFundingFilter: 'Any Funding',
            currentPage: 1
        });
    };

    handleFundingStageChange = event => {
        this.setState({
            fundingStageFilter: event, filter: '', categoryFilter: 'All Categories',
            totalFundingFilter: 'Any Funding',
            currentPage: 1
        });
    };

    handleTotalFundingChange = event => {
        this.setState({
            fundingStageFilter: 'All Funding Stages', filter: '', categoryFilter: 'All Categories',
            totalFundingFilter: event,
            currentPage: 1
        });
    };

    getFilteredData = (listitems, filter, categoryFilter, fundingStageFilter, totalFundingFilter) => {
        if (filter.length > 0) {
            return listitems.filter(item => {
                return ["Name", "Title", "Company",].some(key =>
                    item[key].toString().toLowerCase().includes(filter.toString().toLowerCase())
                );
            })
        } else if (categoryFilter != 'All Categories') {
            return listitems.filter(item => {
                return 'Categories' in item['Crunchbase'] &&
                    item['Crunchbase']['Categories'].toString().toLowerCase().includes(categoryFilter.toString().toLowerCase())

            })
        } else if (fundingStageFilter != 'All Funding Stages') {
            return listitems.filter(item => {
                return 'Company Latest Funding Stage' in item['Crunchbase'] &&
                    item['Crunchbase']['Company Latest Funding Stage'].toString().toLowerCase().includes(fundingStageFilter.toString().toLowerCase())

            })
        } else if (totalFundingFilter != 'Any Funding') {
            return listitems.filter(item => {
                var is_public = 'Ipo Status' in item['Crunchbase'] && item['Crunchbase']['Ipo Status'] === 'public'
                if (totalFundingFilter === 'Public')
                    return is_public
                if ('Company Total Funding' in item['Crunchbase'] && !is_public) {
                    if (totalFundingFilter === '1B+') {
                        return Math.abs(Number(item['Crunchbase']['Company Total Funding'])) >= 1.0e+9
                    } else {
                        var ranges = totalFundingFilter.split('-')
                        var start = ranges[0].replace('M', '000000')
                        var end = ranges[1].replace('M', '000000')
                        return Math.abs(Number(item['Crunchbase']['Company Total Funding'])) >= Number(start) &&
                            Math.abs(Number(item['Crunchbase']['Company Total Funding'])) < Number(end)
                    }
                }
                return false;
            })
        } else
            return listitems

    };
    convertToInternationalCurrencySystem = (labelValue) => {

        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9

            ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
            // Six Zeroes for Millions
            : Math.abs(Number(labelValue)) >= 1.0e+6

                ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
                // Three Zeroes for Thousands
                : Math.abs(Number(labelValue)) >= 1.0e+3

                    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

                    : Math.abs(Number(labelValue));

    }

    render() {
        const {
            listitems,
            currentPage,
            cardsPerPage,
            filter,
            isPortfolio,
            requestLogs,
            categoryFilter,
            fundingStageFilter,
            totalFundingFilter
        } = this.state;
        const filteredData = this.getFilteredData(listitems, filter, categoryFilter, fundingStageFilter, totalFundingFilter);

        const indexOfLastCard = currentPage * cardsPerPage;
        const indexOfFirstCard = indexOfLastCard - cardsPerPage;
        const pageItems = filteredData.slice(indexOfFirstCard, indexOfLastCard);
        const renderCards = pageItems.map(card => {
            return (
                <Col style={{marginBottom: '20px', marginTop: '0px'}}>
                    <Card style={{height: '100%'}}>
                        <Container className="align-middle" style={{height: '80px'}}>
                            <Card.Img variant="top"
                                      style={{marginTop: '20px', marginBottom: '20px', width: '170px', height: 'auto'}}
                                      src={card.Logo}/>
                        </Container>
                        <Card.Body className="d-flex flex-column">
                            <Card.Title>{card.Name}</Card.Title>
                            <Card.Text style={{height: '60px'}}>
                                <p>{card.Title}</p>
                            </Card.Text>
                            <Card.Text>
                                {'Company Total Funding' in card.Crunchbase ? <p><b>Total
                                    Funding</b>: {this.convertToInternationalCurrencySystem(card.Crunchbase['Company Total Funding'])}
                                </p> : null}
                                {'Company Latest Funding Stage' in card.Crunchbase ?
                                    <p><b>Last Funding Stage</b>: {card.Crunchbase['Company Latest Funding Stage']}
                                    </p> : null}
                                {'Company Num Employees' in card.Crunchbase ?
                                    <p><b># Employees</b>: {card.Crunchbase['Company Num Employees']}</p> : null}
                                {'Company Location' in card.Crunchbase ?
                                    <p><b>Location</b>: {card.Crunchbase['Company Location']}</p> : null}
                                {'Categories' in card.Crunchbase ?
                                    <p><b>Categories</b>: {card.Crunchbase['Categories']}</p> : null}
                            </Card.Text>
                            <div className="text-center" style={{marginBottom: '10px'}}>
                                <Button className="linkedin-button" href={card.Linkedin} target="blank"
                                        size='sm'>LinkedIn</Button>
                            </div>
                            <Form.Text className="text-muted text-left">Write a customized note to this mentor to get a
                                better response:</Form.Text>
                            <Form.Control disabled={card.isDisabled} id={card.ID - 1} value={card.Message}
                                          name={card.ID - 1} style={{marginBottom: '10px'}} as="textarea" rows={3}
                                          onChange={event => this.updateValue(event)}/>
                            <Button
                                id={card.ID - 1}
                                value={card.Message}
                                //had to do -1 because airtable auto numbering starts from 1 instead of 0
                                style={{backgroundColor: card.bgColor, border: 'none', fontFamily: 'TradeGothic'}}
                                onClick={e => this.boxClick(e)}
                                className="mt-auto btn btn-lg btn-block"
                                disabled={card.isDisabled}>
                                {card.buttonText}
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            );
        });


        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(filteredData.length / cardsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <Pagination.Item
                    className={(this.state.currentPage === number ? 'active ' : '') + 'controls'}
                    key={number}
                    id={number}
                    onClick={this.handleClick}>
                    {number}
                </Pagination.Item>
            );
        });
        console.log(requestLogs)
        const requests = requestLogs.map(request_row => {
            return (
                <Container className="border rounded">
                    <Row style={{padding: '20px'}}>
                        <Col className="col-2 row-align ">{request_row['Created'].split("T")[0]}</Col>
                        <Col className="col-2 row-align ">{request_row['Customer']}</Col>
                        <Col className="col-3 row-align ">{request_row['Requester Email']}</Col>
                        {request_row['Status'] == 'Pending' &&
                        <Col className="col-2 text-left">
                            <Row>
                                <Button style={{
                                    backgroundColor: '#9910FF',
                                    border: 'none'
                                }}>{request_row['Status']}
                                </Button>
                            </Row>
                            <Row className="updated-time">
                                Last updated: {request_row['Last Modified']}
                            </Row>
                        </Col>
                        }
                        {request_row['Status'] == 'Requested' &&
                        <Col className="col-2 text-left">
                            <Row>
                                <Button style={{
                                    backgroundColor: '#FBB100',
                                    border: 'none'
                                }}>{request_row['Status']}
                                </Button>
                            </Row>
                            <Row className="updated-time">
                                Last updated: {request_row['Last Modified']}
                            </Row>
                        </Col>
                        }
                        {request_row['Status'] == 'Accepted' &&
                        <Col className="col-2 text-left">
                            <Row>
                                <Button style={{
                                    backgroundColor: '#EE00FF',
                                    border: 'none'
                                }}>{request_row['Status']}
                                </Button>
                            </Row>
                            <Row className="updated-time">
                                Last updated: {request_row['Last Modified']}
                            </Row>
                        </Col>
                        }
                        {request_row['Status'] == 'Intro Email Sent' &&
                        <Col className="col-2 text-left">
                            <Row>
                                <Button style={{
                                    backgroundColor: '#1c0c74',
                                    border: 'none'
                                }}>{request_row['Status']}
                                </Button>
                            </Row>
                            <Row className="updated-time">
                                Last updated: {request_row['Last Modified']}
                            </Row>
                        </Col>
                        }
                        <Col className="col-3 text-left">
                            <Accordion>
                                <Accordion.Toggle eventKey="0" style={{backgroundColor: '#F2F2F2'}}>
                                    View Email
                                    <img
                                        src={dropdown}
                                        width="12"
                                        height="6"
                                        style={{paddingLeft: '2px'}}
                                    />
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" style={{paddingTop: '10px'}}>
                                    <div>
                                        <p><b>Email Body:</b></p>
                                        <Markdown>{request_row['Message']}</Markdown>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            )
        });
        const requestReachedModal = (
            <Modal
                show={this.state.showLimitModal}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}>
                <Modal.Body>
                    <br></br>
                    <p>You've hit your daily request limit. Feel free to continue requesting mentors, but they won't be notified until tomorrow</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>

            </Modal>
        )
        return (
            isPortfolio ?
                <Container style={{paddingBottom: '50px'}}>
                    {requestReachedModal}
                    <Tabs defaultActiveKey="mentor" id="uncontrolled-tab-example"
                          className="mentor_tabs justify-content-center navLink">
                        <Tab eventKey="mentor" title="Mentors">
                            <h2>Hypergrowth Mentor Network</h2>
                            <h5 style={{fontFamily: 'TradeGothic', marginBottom: '40px', lineHeight: '1.5em'}}>Welcome to the Hypergrowth Network. Feel free to click the buttons below to reach out to the mentors. Please note that conversations are meant to be about product or growth related feedback and advice.</h5>
                            <Row>
                                <Col>
                                    <DropdownButton className="text-right" variant="secondary" title={this.state.categoryFilter}
                                                    onSelect={e => this.handleCategoryChange(e)}>
                                        {this.state.allCategories.map(category => {
                                            return <Dropdown.Item eventKey={category}>{category}</Dropdown.Item>
                                        })}
                                    </DropdownButton>
                                </Col>
                                <Col>
                                    <DropdownButton variant="secondary" title={this.state.fundingStageFilter}
                                                    onSelect={e => this.handleFundingStageChange(e)}>
                                        {this.state.allFundingStages.map(stage => {
                                            return <Dropdown.Item eventKey={stage}>{stage}</Dropdown.Item>
                                        })}
                                    </DropdownButton>
                                </Col>
                                <Col>
                                    <DropdownButton className="text-left" variant="secondary" title={this.state.totalFundingFilter}
                                                    onSelect={e => this.handleTotalFundingChange(e)}>
                                        {this.state.allFundingRanges.map(amount => {
                                            return <Dropdown.Item eventKey={amount}>{amount}</Dropdown.Item>
                                        })}
                                    </DropdownButton>
                                </Col>
                            </Row>
                            <br/>
                            <React.Fragment><Col className="w-50 mx-auto"
                                                 style={{marginBottom: '40px'}}><Row><Form.Label>Search
                                for a mentor by company name or title:</Form.Label></Row><Row><Form.Control
                                value={filter}
                                onChange={this.handleSearchChange}/></Row></Col></React.Fragment>
                            <Row xs="1" sm="2" md="3">
                                {renderCards}
                            </Row>
                            <div id="page-numbers">
                                <Pagination>
                                    {renderPageNumbers}
                                </Pagination>
                            </div>
                        </Tab>
                        <Tab eventKey="mentor_email" title="Mentor Request Log">
                            <h2>Mentor Network Email Tracker</h2>
                            <Container>
                                <Row style={{padding: '20px'}}>
                                    <Col className="col-2 text-left"><b>Created</b></Col>
                                    <Col className="col-2 text-left"><b>Mentor</b></Col>
                                    <Col className="col-3 text-left"><b>Requested By</b></Col>
                                    <Col className="col-2 text-left"><b>Request Status</b></Col>
                                    <Col className="col-3 text-left"><b>Email Sent</b></Col>
                                </Row>
                            </Container>
                            {requests}
                        </Tab>
                    </Tabs>
                </Container> : <Container>{requestReachedModal}<h2>Hypergrowth Mentor Network</h2>
                    <h5 style={{fontFamily: 'TradeGothic', marginBottom: '40px', lineHeight: '1.5em'}}>Welcome to the Hypergrowth Network. Feel free to click the buttons below to reach out to the mentors. Please note that conversations are meant to be about product or growth related feedback and advice.</h5>
                    <Row xs="1" sm="2" md="3">
                        {renderCards}
                    </Row>
                    <div id="page-numbers">
                        <Pagination>
                            {renderPageNumbers}
                        </Pagination>
                    </div>
                </Container>

        );
    }
}

export default MentorPage;