import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_user: this.props.currentUser,
            showModal: false,
            feedback:""
        };
        this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount() {
      }

    handleClose() {
        this.setState({
            showModal: false,
            feedback:""
        });
    }

    save = e => {
        this.setState({
        feedback: e.target.value,
        showModal: true
        });

       return fetch(`${process.env.REACT_APP_BACKEND_URL}/feedback`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({ Email: this.props.currentUser.email,
                                Feedback: this.state.feedback
                                })
        });
      };
    render() {
        return (
            <>
            <Container style={{width:'500px'}}>
            <h1>Leave us feedback!</h1>
            <h4>Found a bug or have a feature request? Drop us a note here!</h4>
            <Form.Control  name='feedback' style={{marginBottom:'10px'}} as="textarea" rows={3} value={this.state.feedback} onChange={event => this.setState({feedback: event.target.value})}/>
            <Button onClick={e => this.save(e)} style={{backgroundColor: "#EE00FF"}}>Save</Button>
            </Container>
            <Modal
        show={this.state.showModal}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
        <br></br>
        <p>Thanks for submitting feedback! We are constantly working to improve the experience for founders. Feel free to leave us a message here anytime!</p>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={this.handleClose}>
            Close
        </Button>
        </Modal.Footer>

        </Modal>
            </>
        )
    }
}

export default Feedback;