import React, {useRef, useState, createContext} from "react"
import {useHistory} from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import bsv_white from '../bsv_white.png';
import { Row, Col } from "react-bootstrap";
import background from '../background.png';

export default function Login() {
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { login } = useAuth()
    async function handleSubmit() {
        try {
            setError("")
            setLoading(true)
            await login()
            history.push("/")
        } catch {
            setError("Failed to log in")
        }

        setLoading(false)
    }

    return (
        <div className="LoginPage" style={{ backgroundImage: "url(" + background + ")", backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            <Row>
                <Container className="loginLogo" style={{paddingTop:'35px', textAlign:'left', width:'962px'}}>
                    <a href="https://www.basisset.ventures/">
                        <img
                        src={bsv_white}
                        width="124"
                        alt="BSV logo"/>
                    </a>
                </Container>
            </Row>
            <Row>
                <Container className="text-center">
                    <h1 style={{marginTop:'50px', marginBottom:'40px'}}>The Hypergrowth Network App</h1>
                    <Button style={{width:'170px', height: '60px', borderRadius: '8px', backgroundColor: '#FBB100', color: 'black'}} onClick={async () => {await handleSubmit();}}>SIGN IN WITH GOOGLE</Button>
                </Container>
            </Row>
        </div>
    )

}