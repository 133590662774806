import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {Row, Col, Spinner, Alert} from "react-bootstrap";
import '../fonts/TradeGothic.otf';
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Markdown from 'markdown-to-jsx';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../bsv.png';
import NavItem from 'react-bootstrap/NavItem';
import {Link, NavLink} from 'react-router-dom';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ReactDOM from 'react-dom';
import logout from '../logout2.png';

class Home extends Component {
    constructor(props) {
        super(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClose2 = this.handleClose2.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.state = {
            profile: [],
            showModal: false,
            showEditor: false,
            showProfile: true,
            showInviteModal: false,
            inviteName: "",
            inviteEmail: "",
            inviteMessage: "",
            showInviteType: "",
            showTooltip_1: false,
            showTooltip_2: false,
            showTooltip_3: false,
            showEndModal: false,
            Company: "",
            Website: "",
            Linkedin: "",
            Personal: "",
            Q1: "",
            Q2: "",
            Q3: "",
            Q4: "",
            Q5: "",
            Attachments: [],
            Demo: "",
            showSpinner: false,
            navbarActive: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAttachmentChange = this.handleAttachmentChange.bind(this);
    }

    handleClose() {
        this.setState({
            showModal: false,
            showTooltip_1: true,
            navbarActive: true
        });
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/home_sawModal`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({Email: this.props.currentUser.email})
        });
    }

    handleClose2() {
        this.setState({showEndModal: false});
    }

    handleShow() {
        this.setState({showModal: true});
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        fetch(`${process.env.REACT_APP_BACKEND_URL}/home`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({Email: this.props.currentUser.email})
        })
            .then(res => res.json())
            .then((result) => {
                    if (result[0][0]['Popup'] === 'true') {
                        this.setState({
                            isLoaded: true,
                            profile: result[0][0],
                            showModal: true,
                            Company: result[0][0]['Company'],
                            Website: result[0][0]['Website'],
                            Linkedin: result[0][0]['Linkedin'],
                            Personal: result[0][0]['Personal'],
                            Q1: result[0][0]['one'],
                            Q2: result[0][0]['two'],
                            Q3: result[0][0]['three'],
                            Q4: result[0][0]['four'],
                            Q5: result[0][0]['five'],
                            Attachments: result[0][0]['Attachments'],
                            newAttachments: [],
                            Demo: result[0][0]['Demo']
                        });
                    } else {
                        this.setState({
                            isLoaded: true,
                            profile: result[0][0],
                            showModal: false,
                            Company: result[0][0]['Company'],
                            Website: result[0][0]['Website'],
                            Linkedin: result[0][0]['Linkedin'],
                            Personal: result[0][0]['Personal'],
                            Q1: result[0][0]['one'],
                            Q2: result[0][0]['two'],
                            Q3: result[0][0]['three'],
                            Q4: result[0][0]['four'],
                            Q5: result[0][0]['five'],
                            Attachments: result[0][0]['Attachments'],
                            newAttachments: [],
                            Demo: result[0][0]['Demo']
                        });
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleInputChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if ((!domNode || !domNode.contains(event.target)) && this.state.showTooltip_1 === true) {
            this.setState({
                showTooltip_1: false,
                showTooltip_2: true
            });
        } else if ((!domNode || !domNode.contains(event.target)) && this.state.showTooltip_1 === false && this.state.showTooltip_2 === true) {
            this.setState({
                showTooltip_1: false,
                showTooltip_2: false,
                showTooltip_3: true
            });
        } else if ((!domNode || !domNode.contains(event.target)) && this.state.showTooltip_1 === false && this.state.showTooltip_2 === false && this.state.showModal === true) {
            this.setState({
                showTooltip_1: false,
                showTooltip_2: false,
                showTooltip_3: false
            });
        } else if ((!domNode || !domNode.contains(event.target)) && this.state.showTooltip_3 === true) {
            this.setState({
                showTooltip_1: false,
                showTooltip_2: false,
                showTooltip_3: false,
                showEndModal: true,
                navbarActive: false
            });
        }
    }

    handleAttachmentChange(event, name) {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        data.append('filename', name);
        this.setState({showSpinner: true})
        fetch(`${process.env.REACT_APP_BACKEND_URL}/upload`, {
            method: 'POST',
            body: data,
        }).then(res => res.json())
            .then((result) => {
                this.setState({showSpinner: false})
                console.log(result['filename'])
                let att = this.state.newAttachments.slice()
                att.push(result['filename'])
                this.setState({newAttachments: att});
                console.log(this.state)
            });
    }


    showEditor = e => {
        this.setState({
            showEditor: true,
            showProfile: false,
        });
    };
    handleInviteShow = () => {
        this.setState({showInviteModal: true})
    }
    handleInviteClose = () => {
        this.setState({showInviteModal: false})
    }
    save = e => {
        this.setState({
            showEditor: false,
            showProfile: true,
        });
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/update_profile`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                Email: this.props.currentUser.email,
                Company: this.state.Company,
                Website: this.state.Website,
                Linkedin: this.state.Linkedin,
                Personal: this.state.Personal,
                one: this.state.Q1,
                two: this.state.Q2,
                three: this.state.Q3,
                four: this.state.Q4,
                five: this.state.Q5,
                Demo: this.state.Demo,
                Attachments: this.state.Attachments,
                newAttachments: this.state.newAttachments
            })
        });
    };
    handleInviteSubmit = e => {
        if (this.state.inviteName.length < 2 || this.state.inviteEmail.length < 2 || this.state.inviteEmail.indexOf('@') < 0 )
        {
            this.setState({inviteMessage:'Invalid Name or Email!', showInviteType: "danger"})
            return
        }
        this.setState({inviteMessage:'', showInviteType: ""})
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/invite_colleague`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                Email: this.props.currentUser.email,
                inviteName: this.state.inviteName,
                inviteEmail: this.state.inviteEmail,
            })
        }).then(res => res.json())
            .then((result) => {
                this.setState({showInviteType: result['status'], inviteMessage: result['message']})
            });
    };

    render() {
        const {
            showModal,
            showEndModal,
            showInviteModal,
            inviteName,
            inviteEmail,
            inviteMessage,
            showInviteType,
            profile,
            showProfile,
            showEditor,
            Linkedin,
            Personal,
            Company,
            Website,
            Q1,
            Q2,
            Q3,
            Q4,
            Q5,
            Attachments,
            Demo
        } = this.state;
        const popover1 = (
            <Popover id="popover-1">
                <Popover.Content className='popoverText'>
                    <p>First, check out the <b>Mentor Network</b> tab. Feel free to scroll through the pages and click
                        on the button to request and intro.</p>
                    <p> You'll be asked to write a customized note to each mentor, which ensures a better response</p>
                    <div className="text-center">
                        <Button style={{backgroundColor: '#591bff', color: 'white'}}>Next</Button>
                    </div>
                </Popover.Content>
            </Popover>
        );
        const popover2 = (
            <Popover id="popover-2">
                <Popover.Content>
                    <p className='popoverText'> Second, check out the <b>Recruiting Network</b> tab. </p>
                    <p>Here, you can find the best folks across marketing, sales, and growth to and reach out to them to
                        connect.
                        Feel free to create a baseline template to use for email outreach, and you can customize it for
                        each applicant as well. </p>
                    <div className="text-center">
                        <Button style={{backgroundColor: '#591bff', color: 'white'}}>Next</Button>
                    </div>
                </Popover.Content>
            </Popover>
        );
        const popover3 = (
            <Popover id="popover-3">
                <Popover.Content>
                    <p className='popoverText'>Finally, in the <b>Recruiting Tracker</b> tab you can check the log to
                        track who you’ve reached out to, and who has responded.</p>
                    <div className="text-center">
                        <Button style={{backgroundColor: '#591bff', color: 'white'}}>Next</Button>
                    </div>
                </Popover.Content>
            </Popover>
        );
        return (
            <>
                <Navbar collapseOnSelect expand="md" sticky="top"
                        style={{top: '0', backgroundColor: 'white', width: '100%'}}>
                    <Container>
                        <Navbar.Brand as={Link} to="/">
                            <img
                                src={logo}
                                width="30"
                                height="30"
                                alt="BSV logo"/>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <div className="navbar-nav ml-auto">
                            <NavItem eventkey={0} href="/">
                                <Button style={{backgroundColor: '#9910FF', border: 'none'}} onClick={this.handleInviteShow}>
                                Invite a teammate
                                </Button>
                            </NavItem>
                                <NavItem eventkey={1} href="/">
                                    <Nav.Link disabled={this.state.navbarActive} activeClassName="active"
                                              className="navLink" as={NavLink} exact
                                              to="/">Home</Nav.Link>
                                </NavItem>

                                <OverlayTrigger show={this.state.showTooltip_1} overlay={popover1} placement="bottom">
                                    <NavItem eventkey={2} href="/network">
                                        <Nav.Link disabled={this.state.navbarActive} activeClassName="active"
                                                  className="navLink" as={NavLink} exact
                                                  to="/network">Mentor Network</Nav.Link>
                                    </NavItem>
                                </OverlayTrigger>

                                <OverlayTrigger show={this.state.showTooltip_2} overlay={popover2} placement="bottom">
                                    <NavItem eventkey={3} href="/recruitment">
                                        <Nav.Link disabled={this.state.navbarActive} activeClassName="active"
                                                  className="navLink" as={NavLink} exact
                                                  to="/recruitment">Recruitment Network</Nav.Link>
                                    </NavItem>
                                </OverlayTrigger>

                                <OverlayTrigger show={this.state.showTooltip_3} overlay={popover3} placement="bottom">
                                    <NavItem eventkey={4} href="/recruitment_tracker">
                                        <Nav.Link disabled={this.state.navbarActive} activeClassName="active"
                                                  className="navLink" as={NavLink} exact
                                                  to="/recruitment_tracker">Recruitment Tracker</Nav.Link>
                                    </NavItem>
                                </OverlayTrigger>
                                <NavItem eventkey={5} href="/feedback">
                                    <Nav.Link disabled={this.state.navbarActive} activeClassName="active"
                                              className="navLink" as={NavLink} exact
                                              to="/feedback">Leave us feedback!</Nav.Link>
                                </NavItem>
                                <Nav.Link disabled={this.state.navbarActive} activeClassName="active"
                                          className="navLink" as={NavLink} exact
                                          to="/logout">
                                    <img
                                        src={logout}
                                        width="25"
                                        style={{paddingBottom: '5px'}}
                                        alt="Logout?"/>
                                </Nav.Link>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <Modal show={showInviteModal} onHide={this.handleInviteClose}>
                    <Modal.Header closeButton>
                        <Col>
                            <Row>
                                <Modal.Title>Invite a teammate</Modal.Title>                               
                            </Row>
                            <Row>
                                <p>Any team member invited will be able to use the Hypergrowth Network on behalf of your company</p>
                            </Row>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        {showInviteType ? <Alert variant={showInviteType}>{inviteMessage}</Alert> : null}
                        <h5>Name:</h5>
                        <Form.Control id="inviteName" defaultValue={inviteName}
                                      onChange={e => this.handleInputChange(e, "inviteName")}/>
                        <h5>Email:</h5>
                        <Form.Control id="inviteEmail" defaultValue={inviteEmail}
                                      onChange={e => this.handleInputChange(e, "inviteEmail")}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleInviteClose}>
                            Close
                        </Button>
                        <Button style={{backgroundColor: '#9910FF', border: 'none'}}  onClick={this.handleInviteSubmit}>
                            Invite
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Container>
                    <h2> Hi, {profile.Name}!</h2>
                    <h5>Feel free to edit your application as the mentors will receive this information when reviewing
                        requests.</h5>
                    <p>Have feedback on the app? <a href='/feedback'>Leave us a message!</a></p>
                </Container>
                {showProfile
                    ?
                    <Container>
                        <div className="text-left"> 
                        <Button onClick={e => this.showEditor(e)}
                        style={{backgroundColor: "#1c0c74", marginRight: '10px', marginBottom:'5px'}}>Edit</Button>
                        </div>
                        <Card className="text-left" style={{padding: '50px'}}>
                            <Form>
                                <Col className="profile-row text-left">
                                    <Row className="font-weight-bold"> LinkedIn: </Row>
                                    <Row className="response-row"><a target="_blank"
                                                                     href={Linkedin}>{Linkedin}</a></Row>
                                </Col>
                                <Col className="profile-row text-left">
                                    <Row className="font-weight-bold">Any other online personal space (ie Website,
                                        Twitter, Github, etc):</Row>
                                    <Row className="response-row"><Markdown>{Personal}</Markdown></Row>
                                </Col>
                                <Col className="profile-row text-left">
                                    <Row className="font-weight-bold">Company Name:</Row>
                                    <Row className="response-row"><Markdown>{Company}</Markdown></Row>
                                </Col>
                                <Col className="profile-row text-left">
                                    <Row className="font-weight-bold">Website: </Row>
                                    <Row className="response-row"><a target="_blank"
                                                                     href={Website.includes('http') ? Website : 'https://' + Website}>{Website}</a></Row>
                                </Col>
                                <Col className="profile-row text-left">
                                    <Row className="font-weight-bold">Do you have team members? If so, please add their
                                        names and LinkedIns:</Row>
                                    <Row className="response-row"><Markdown>{Q1}</Markdown></Row>
                                </Col>
                                <Col className="profile-row text-left">
                                    <Row className="font-weight-bold">In under 250 words, tell us about what you are
                                        building. Who is your target audience, and what
                                        pain point are you solving for them? Why do your customers need your product
                                        today?:</Row>
                                    <Row className="response-row"><Markdown>{Q2}</Markdown></Row>
                                </Col>
                                <Col className="profile-row text-left">
                                    <Row className="font-weight-bold">
                                        If applicable, tell us about how you are building the product, e.g. how
                                        frequently
                                        do you ship, how often do you talk to customers, and how you decide what to
                                        build: </Row>
                                    <Row className="response-row"><Markdown>{Q3}</Markdown></Row>
                                </Col>
                                <Col className="profile-row text-left">
                                    <Row className="font-weight-bold">If applicable, please give us details on any
                                        pipeline, pilots, paying customers, or revenue you have today:</Row>
                                    <Row className="response-row"><Markdown>{Q4}</Markdown></Row>
                                </Col>
                                <Col className="profile-row text-left">
                                    <Row className="font-weight-bold">
                                        In under 250 words, tell us anything else you’d like us to know (For example,
                                        you could
                                        tell us about why your experience makes you best suited to build this product):
                                    </Row>
                                    <Row className="response-row"><Markdown>{Q5}</Markdown></Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">Attachments:</Row>
                                    <Row className="response-row">
                                        {Attachments.map(url => {
                                            return <a style={{paddingRight: '10px'}} target="_blank" href={url}>Attachment
                                                Link</a>
                                        })}
                                    </Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">Demo:</Row>
                                    <Row className="response-row"><p>{Demo}</p></Row>
                                </Col>
                            </Form>
                        </Card>
                    </Container>
                    : null
                }
                {showEditor
                    ?
                    <Container>
                    <div className="text-left">{this.state.showSpinner ? <Spinner animation='border'/> :
                        <Button onClick={e => this.save(e)}
                                style={{backgroundColor: "#EE00FF", marginBottom:'5px'}}>Save</Button>}</div>
                        <Card className="text-left" style={{padding: '20px'}}>
                            <Form>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">Linkedin: </Row>
                                    <Row>
                                        <Form.Control id="Linkedin"
                                                      onChange={e => this.handleInputChange(e, "Linkedin")}
                                                      value={Linkedin}/>
                                    </Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">Any other online personal space (ie Website,
                                        Twitter, Github, etc):</Row>
                                    <Row><Form.Control id="Personal" defaultValue={Personal}
                                                       onChange={e => this.handleInputChange(e, "Personal")}/></Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">Company Name: </Row>
                                    <Row><Form.Control disabled="true" id="Company" defaultValue={Company}
                                                       onChange={e => this.handleInputChange(e, "Company")}/></Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">Website: </Row>
                                    <Row><Form.Control id="Website" defaultValue={Website}
                                                       onChange={e => this.handleInputChange(e, "Website")}/></Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">Do you have team members? If so, please add their
                                        names and LinkedIns:</Row>
                                    <Row><Form.Control as="textarea" rows={3} id="Q1" defaultValue={Q1}
                                                       onChange={e => this.handleInputChange(e, "Q1")}/></Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">In under 250 words, tell us about what you are
                                        building. Who is your target audience, and what
                                        pain point are you solving for them? Why do your customers need your product
                                        today?:</Row>
                                    <Row><Form.Control id="Q2" as="textarea" rows={3} defaultValue={Q2}
                                                       onChange={e => this.handleInputChange(e, "Q2")}/></Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">If applicable, tell us about how you are building
                                        the product, e.g. how frequently
                                        do you ship, how often do you talk to customers, and how you decide what to
                                        build: </Row>
                                    <Row><Form.Control as="textarea" rows={3} id="Q3" defaultValue={Q3}
                                                       onChange={e => this.handleInputChange(e, "Q3")}/></Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">
                                        If applicable, please give us details on any pipeline, pilots, paying customers,
                                        or revenue you have today.:
                                    </Row>
                                    <Row><Form.Control as="textarea" rows={3} id="Q4" defaultValue={Q4}
                                                       onChange={e => this.handleInputChange(e, "Q4")}/></Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">
                                        In under 250 words, tell us anything else you’d like us to know (For example,
                                        you could
                                        tell us about why your experience makes you best suited to build this product).:
                                    </Row>
                                    <Row><Form.Control as="textarea" rows={3} id="Q5" defaultValue={Q5}
                                                       onChange={e => this.handleInputChange(e, "Q5")}/></Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold">Upload Another Attachment:</Row>
                                    <Row><Form.Label className="text-muted text-left">Max file size
                                        800MB</Form.Label></Row>
                                    <Row>
                                        <Form.File type="file" id="attachments"
                                                   onChange={(e) => this.handleAttachmentChange(e, "Attachments")}/>
                                    </Row>
                                    <Row><p className="text-muted"
                                            style={{fontSize: '11px', paddingTop: '3px'}}> Contact us to delete any
                                        previous attachments</p></Row>
                                </Col>
                                <Col className="profile-row">
                                    <Row className="font-weight-bold"> Demo:</Row>
                                    <Row><Form.Control id="Linkedin" defaultValue={Demo}
                                                       onChange={e => this.handleInputChange(e, "Demo")}/></Row>
                                </Col>
                                <div className="text-center">{this.state.showSpinner ? <Spinner animation='border'/> :
                                    <Button onClick={e => this.save(e)}
                                            style={{backgroundColor: "#EE00FF"}}>Save</Button>}</div>

                            </Form>
                        </Card>

                    </Container>
                    : null
                }
                <Modal
                    show={showModal}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false} style={{borderColor: '#591bff'}}>
                    <Modal.Body style={{backgroundColor: '#EAE5FF', color: 'black'}}>
                        <Modal.Header><h6><b>Welcome to the Hypergrowth Network, {profile.Name}! </b></h6>
                        </Modal.Header>
                        <br></br>
                        <p>We’re thrilled to be able to connect you with the best advice from leaders across tech.
                            We have a few things to show you to get started:</p>

                        <div className="text-center">
                            <Button style={{backgroundColor: '#591bff', color: 'white'}} onClick={this.handleClose}>
                                Next
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={showEndModal}
                    onHide={this.handleClose2}
                    backdrop="static"
                    keyboard={false} style={{borderColor: '#591bff'}}>
                    <Modal.Body style={{backgroundColor: '#EAE5FF', color: 'black'}}>
                        Thanks so much for checking out our app! Feel free to send feedback <a href='/feedback'>here</a>
                        <div className="text-center">
                            <Button style={{backgroundColor: '#591bff', color: 'white'}} onClick={this.handleClose2}>
                                Close
                            </Button>
                        </div>
                    </Modal.Body>

                </Modal>
            </>
        )

    }
}

export default Home;