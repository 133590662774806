import {Component} from "react";
import bsv_white from '../bsv_white.png';
import { Row, Col } from "react-bootstrap";
import background from '../background.png';
import Container from "react-bootstrap/Container";

class CustomerOptIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rec_id: this.props.match.params.rec_id
        };
    }
    componentDidMount() {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/customer_opt_in`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({Id: this.state.rec_id})
        })
            .then(res => res.json())
            .then((result) => {
                    console.log(result)
                    this.setState({
                        Customer: result['Customer']
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }
    render() {
        const {Customer} = this.state;
        return (
            <div className="LoginPage" style={{ backgroundImage: "url(" + background + ")", backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            <Row>
                <Container className="loginLogo" style={{paddingTop:'35px', textAlign:'left', width:'962px'}}>
                    <a href="https://www.basisset.ventures/">
                        <img
                        src={bsv_white}
                        width="124"
                        alt="BSV logo"/>
                    </a>
                </Container>
            </Row>
            <Row>
                <Container className="text-center">
                    <h2 style={{marginTop:'50px', marginBottom:'40px'}}>
                     Thank you!
                    </h2>
                    <h3>Your response has been recorded and an introduction email will be sent shortly!</h3>
                </Container>
            </Row>
        </div>
        )
    }
}

export default CustomerOptIn;