import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../bsv.png';
import '../fonts/TradeGothic.otf';
import NavItem from 'react-bootstrap/NavItem';
import {Link, NavLink} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import logout from '../logout2.png';
import Button from "react-bootstrap/Button"; 
import {Row, Col, Spinner, Alert} from "react-bootstrap";
import '../fonts/TradeGothic.otf';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_user: this.props.currentUser,
            showInviteModal: false,
            showInviteType: "",
            inviteName: "",
            inviteEmail: "",
            inviteMessage: ""
        };
    this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInviteShow = () => {
        this.setState({showInviteModal: true})
    }

    handleInputChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    handleInviteClose = () => {
        this.setState({showInviteModal: false})
    }
    handleInviteSubmit = e => {
        console.log(this.state.current_user.email)
        if (this.state.inviteName.length < 2 || this.state.inviteEmail.length < 2 || this.state.inviteEmail.indexOf('@') < 0 )
        {
            this.setState({inviteMessage:'Invalid Name or Email!', showInviteType: "danger"})
            return
        }
        this.setState({inviteMessage:'', showInviteType: ""})
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/invite_colleague`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                Email: this.props.currentUser.email,
                inviteName: this.state.inviteName,
                inviteEmail: this.state.inviteEmail,
            })
        }).then(res => res.json())
            .then((result) => {
                this.setState({showInviteType: result['status'], inviteMessage: result['message']})
            });
    };
    render() {
        const{showInviteModal, showInviteType, inviteEmail, inviteMessage, inviteName} = this.state;
        return (
            <React.Fragment>
            <Modal show={showInviteModal} onHide={this.handleInviteClose}>
            <Modal.Header closeButton>
                <Col>
                    <Row>
                        <Modal.Title>Invite a teammate</Modal.Title>                               
                    </Row>
                    <Row>
                        <p>Any team member invited will be able to use the Hypergrowth Network on behalf of your company</p>
                    </Row>
                </Col>
            </Modal.Header>
            <Modal.Body>
                {showInviteType ? <Alert variant={showInviteType}>{inviteMessage}</Alert> : null}
                <h5>Name:</h5>
                <Form.Control id="inviteName" defaultValue={inviteName}
                                onChange={e => this.handleInputChange(e, "inviteName")}/>
                <h5>Email:</h5>
                <Form.Control id="inviteEmail" defaultValue={inviteEmail}
                                onChange={e => this.handleInputChange(e, "inviteEmail")}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleInviteClose}>
                    Close
                </Button>
                <Button style={{backgroundColor: '#9910FF', border: 'none'}}  onClick={this.handleInviteSubmit}>
                    Invite
                </Button>
            </Modal.Footer>
        </Modal>
        
        <Navbar collapseOnSelect expand="md" sticky="top"
                style={{top: '0', backgroundColor: 'white', width: '100%'}}>
            <Container>
            <Navbar.Brand as={Link} to="/">
                <img
                    src={logo}
                    width="30"
                    height="30"
                    alt="BSV logo"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            <div className="navbar-nav ml-auto">
                <NavItem eventkey={0} href="/">
                    <Button style={{backgroundColor: '#9910FF', border: 'none'}} onClick={this.handleInviteShow}>
                    Invite a teammate
                    </Button>
                </NavItem>
                <NavItem eventkey={1} href="/">
                    <Nav.Link activeClassName="active" className="navLink" as={NavLink} exact
                                to="/">Home</Nav.Link>
                </NavItem>
                <NavItem eventkey={2} href="/network">
                    <Nav.Link activeClassName="active" className="navLink" as={NavLink} exact
                                to="/network">Mentor Network</Nav.Link>
                </NavItem>
                <NavItem eventkey={3} href="/recruitment">
                    <Nav.Link activeClassName="active" className="navLink" as={NavLink} exact
                                to="/recruitment">Recruitment Network</Nav.Link>
                </NavItem>
                <NavItem eventkey={4} href="/recruitment_tracker">
                    <Nav.Link activeClassName="active" className="navLink" as={NavLink} exact
                                to="/recruitment_tracker">Recruitment Tracker</Nav.Link>
                </NavItem>
                <NavItem eventkey={5} href="/feedback">
                    <Nav.Link activeClassName="active" className="navLink" as={NavLink} exact
                                to="/feedback">Leave us feedback!</Nav.Link>
                </NavItem>
                <NavItem eventkey={6} href="/logout">
                    <Nav.Link activeClassName="active" className="navLink" as={NavLink} exact
                                to="/logout">
                        <img
                        src={logout}
                        width="25"
                        style={{paddingBottom:'5px'}}
                        alt="Logout?"/>                                             
                    </Nav.Link>
                </NavItem>
            </div>
            </Navbar.Collapse>
            </Container>
        </Navbar>
        </React.Fragment>
        );
    }
}

export default NavBar;