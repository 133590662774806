import React, {useContext, useState, useEffect} from "react"
import {signInWithGoogle, auth} from "../firebase/firebase.utils"
import {useHistory} from "react-router-dom"

const AuthContext = React.createContext()
export default  AuthContext;
export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [redirectUrl, setRedirectUrl] = useState('/login')
    const [invalidUser, setInvalidUser] = useState(false)
    const history = useHistory()

    function signup() {
        return signInWithGoogle()
    }

    function login() {
        return signInWithGoogle()
    }

    function logout() {
        return auth.signOut()
    }

    auth.getRedirectResult().then(function (result) {
        var user = result.user;
        if (user !== null) {
                fetch(`${process.env.REACT_APP_BACKEND_URL}/disable_user`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({Email: user.email})
            })
                .then(res => res.json())
                .then((result) => {
                        if (result['message'] === 'denied') {
                            history.push('/invalid_user')
                        } else {
                            history.push('/')
                        }
                    }
                ).catch((error) => {
                console.log(error.code);
                console.log(error.message);
            });
        }
    }, function (error) {
        console.log(error.message);
        history.push('/invalid_user');

    });


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])
    const value = {
        currentUser,
        redirectUrl,
        login,
        logout
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
