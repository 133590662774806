// firebase.utils.js
import firebase from 'firebase/app';
import 'firebase/auth';
const config = {
    apiKey: "AIzaSyBrm2stBUwRSsa3q940fx3-5WQ2C8c1t20",
    authDomain: "gtmproject-30932.firebaseapp.com",
    projectId: "gtmproject-30932",
    storageBucket: "gtmproject-30932.appspot.com",
    messagingSenderId: "378434302475",
    appId: "1:378434302475:web:ae6eea410ff7fda42ccd0e",
    measurementId: "G-RG5JC6M0Z9"
};

firebase.initializeApp(config);


export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({prompt: 'select_account'});
export const signInWithGoogle = () => auth.signInWithRedirect(provider);

export const signOut = () => auth.signOut();

export default firebase;
