import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {Row, Col} from "react-bootstrap";
import '../fonts/TradeGothic.otf';
import Pagination from "react-bootstrap/Pagination"
import Accordion from "react-bootstrap/Accordion"
import dropdown from '../Chevron.png';
import Markdown from 'markdown-to-jsx';

class RecruitmentTracker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            log_items: [],
            currentPage: 1,
            cardsPerPage: 10,
            current_user: this.props.currentUser
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        this.setState({
          currentPage: Number(event.target.id)
        });
      }
    
    componentDidMount() {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/recruitment_log`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({Email: this.props.currentUser.email})
        })
            .then(res => res.json())
            .then((result) => {
                    console.log(result)
                    this.setState({
                        isLoaded: true,
                        log_items: result[0]
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { log_items, currentPage, cardsPerPage} = this.state;
        const indexOfLastCard = currentPage * cardsPerPage;
        const indexOfFirstCard = indexOfLastCard - cardsPerPage;
        const pageItems =  log_items.slice(indexOfFirstCard, indexOfLastCard);
        const renderEmails =  pageItems.map(log_item => {
          return(
            <Container className="border rounded">
                <Row style={{padding: '20px'}}>
                    <Col className="col-2 row-align ">{log_item.Created}</Col>

                    <Col className="col-3 row-align">{log_item.Candidate}</Col>
                
                    {log_item.EmailActivity == 'Processing' &&
                    <Col className="col-3 ">
                        <Row>
                            <Button style={{
                            backgroundColor: '#9910FF',
                            border: 'none'}}>{log_item.EmailActivity}
                            </Button>
                        </Row>
                        <Row className="updated-time">
                            Last updated: {log_item.Updated}
                        </Row>
                    </Col>
                    }
                    {log_item.EmailActivity == 'Delivered' &&
                    <Col className="col-3">
                        <Row>
                            <Button style={{
                            backgroundColor: '#1c0c74',
                            border: 'none'}}>{log_item.EmailActivity}
                            </Button>
                        </Row>
                        <Row className="updated-time">
                            Last updated: {log_item.Updated}
                        </Row>
                    </Col>
                    }
                    {log_item.EmailActivity.includes('Opened') &&
                    <Col className="col-3 ">
                        <Row>
                            <Button style={{
                            backgroundColor: '#EE00FF',
                            border: 'none'}}>{log_item.EmailActivity}
                            </Button>
                        </Row>
                        <Row className="updated-time">
                            Last updated: {log_item.Updated}
                        </Row>
                    </Col>
                    }
                    {log_item.EmailActivity == 'Bounced' &&
                    <Col className="col-3">
                        <Row>
                            <Button style={{
                            backgroundColor: '#FBB100',
                            border: 'none'}}>{log_item.EmailActivity}
                            </Button>
                        </Row>
                        <Row className="updated-time">
                            Last updated: {log_item.Updated}
                        </Row>
                    </Col>
                    }
                    <Col className="col-4 text-left">
                        <Accordion>
                            <Accordion.Toggle eventKey="0" style={{backgroundColor:'#F2F2F2'}}>
                                View Email
                                <img
                                src={dropdown}
                                width="12"
                                height="6"
                                style= {{paddingLeft:'2px'}}
                                />
                            </Accordion.Toggle> 
                            <Accordion.Collapse eventKey="0" style={{paddingTop:'10px'}}>
                                <div>
                                <p><b>Email:</b> {log_item.CandidateEmail}</p>
                                <p><b>Subject:</b> {log_item.Subject}</p>
                                <p><b>Email Body:</b></p>
                                <Markdown>{log_item.Template}</Markdown>
                                </div>
                            </Accordion.Collapse>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        );
        });

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(log_items.length / cardsPerPage); i++) {
          pageNumbers.push(i);
        }
    
        const renderPageNumbers = pageNumbers.map(number => {
          return (
            <Pagination.Item
             className={(this.state.currentPage === number ? 'active ' : '') + 'controls'}
              key={number}
              id={number}
              onClick={this.handleClick} >
              {number}
            </Pagination.Item>
          );
        });
    
        return (
            <Container style={{paddingBottom:'50px'}}>
                <h2>Recruitment Tracker</h2>
                <Container className="border-bottom">
                    <Row style={{padding: '20px'}}>
                        <Col className="col-2 text-left"><b>Created</b></Col>
                        <Col className="col-3 text-left"><b>Candidate Name</b></Col>
                        <Col className="col-3 text-left"><b>Email Status</b></Col>
                        <Col className="col-4 text-left"><b>Email Sent</b></Col>
                    </Row>
                    <Row>
                        {renderEmails}
                    </Row>
                </Container>
                <div id="page-numbers" style={{marginTop:'10px'}}>
                <Pagination>
                    {renderPageNumbers}
                </Pagination>
                </div>
            </Container>
        );
    }
}

export default RecruitmentTracker ;