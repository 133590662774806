import React, {useRef, useState} from "react"
import {useAuth} from "../contexts/AuthContext"
import {useHistory} from "react-router-dom"

export default function Logout() {
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const {logout} = useAuth()

    async function handleSubmit() {
        try {
            setError("")
            setLoading(true)
            await logout()
            history.push('/login')
        } catch {
            setError("Failed to log out")
        }

        setLoading(false)
    }

    return (
        <>
            <h2 className="text-center mb-4">Log Out</h2>
            <button onClick={async () => {
                await handleSubmit();
            }}>SIGN OUT
            </button>
        </>
    )

}