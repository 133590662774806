import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import {Col, Row} from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import Container from "react-bootstrap/Container";
import {Link} from 'react-router-dom'
import Button from "react-bootstrap/Button";

class RequesterProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: [],
            Website: "",
            Linkedin: "",
            Personal: "",
            Q1: "",
            Q2: "",
            Q3: "",
            Q4: "",
            Q5: "",
            Attachments: [],
            Demo: "",
            rec_id: this.props.match.params.rec_id
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/get_requester_profile`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({Id: this.state.rec_id})
        })
            .then(res => res.json())
            .then((result) => {
                    console.log(result)
                    this.setState({
                        profile: result,
                        Linkedin: result['Linkedin'],
                        Personal: result['Personal'],
                        Customer: result['Customer'],
                        Q1: result['one'],
                        Q2: result['two'],
                        Q3: result['three'],
                        Q4: result['four'],
                        Q5: result['five'],
                        Attachments: result['Attachments'],
                        Demo: result['Demo'],
                        Website: result['Website'],
                        Name: result['Name']
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        error
                    });
                }
            )

    }

    render() {
        const {Name, Linkedin, Personal, Customer, Website, Q1, Q2, Q3, Q4, Q5, Attachments, Demo, rec_id} = this.state;
        return (
            <Container style={{paddingBottom: '50px'}}>
                <h4>Hi, {Customer}! </h4>
                <h5><a href={Linkedin} target="_blank">{Name}</a> has requested to connect with you.</h5><h5>Here is more information about what they are working on:</h5> 
                <Card className="text-left" style={{padding:'50px'}} >
                    <Form>
                        <Col className="profile-row text-left">
                            <Row className="font-weight-bold"> LinkedIn: </Row>
                            <Row className="response-row"><a href={Linkedin}>{Linkedin}</a></Row>
                        </Col>
                        <Col className="profile-row text-left">
                            <Row className="font-weight-bold">Any other online personal space (ie Website, Twitter, Github, etc):</Row>
                            <Row className="response-row"><Markdown>{Personal}</Markdown></Row>
                        </Col>
                        <Col className="profile-row text-left">
                            <Row className="font-weight-bold">Website: </Row>
                            <Row className="response-row"><a href={Website}>{Website}</a></Row>
                        </Col>
                        <Col className="profile-row text-left">
                            <Row className="font-weight-bold">Do you have team members? If so, please add their names and LinkedIns:</Row> 
                            <Row className="response-row">{Q1}</Row>
                        </Col>
                        <Col className="profile-row text-left">
                            <Row className="font-weight-bold">In under 250 words, tell us about what you are building. Who is your target audience, and what 
                            pain point are you solving for them? Why do your customers need your product today?:</Row>
                            <Row className="response-row"><p>{Q2}</p></Row>
                        </Col>
                        <Col className="profile-row text-left">
                            <Row className="font-weight-bold"> 
                            If applicable, tell us about how you are building the product, e.g. how frequently 
                            do you ship, how often do you talk to customers, and how you decide what to build: </Row>
                            <Row className="response-row"><p>{Q3}</p></Row>
                        </Col>
                        <Col className="profile-row text-left">
                            <Row className="font-weight-bold">If applicable, please give us details on any pipeline, pilots, paying customers, or revenue you have today:</Row>
                            <Row className="response-row"><p>{Q4}</p></Row>
                        </Col>
                        <Col className="profile-row text-left">
                            <Row className="font-weight-bold"> 
                            In under 250 words, tell us anything else you’d like us to know (For example, you could 
                            tell us about why your experience makes you best suited to build this product): 
                            </Row>
                            <Row className="response-row"><p>{Q5}</p></Row>
                        </Col>
                        <Col className="profile-row"> 
                            <Row className="font-weight-bold">Attachments:</Row>
                            <Row className="response-row"><a href={Attachments}>Current Attachment</a></Row>
                        </Col>
                        <Col className="profile-row">
                            <Row className="font-weight-bold">Demo:</Row> 
                            <Row className="response-row"><p>{Demo}</p></Row>
                        </Col>
                    </Form>
                </Card>
                <Link to={`/opt_in/${rec_id}`}>
                    <Button style={{width:'200px', backgroundColor:'#9910FF', fontSize:'18px'}} className="btn">Opt-In</Button>
                </Link>
            </Container>
        )
    }
}

export default RequesterProfile;