import React, {Component} from "react";
import '../styles/App.css';
import NavBar from './NavBar.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Login.js';
import {Switch, Route, withRouter} from 'react-router-dom';
import Recruitment from './Recruitment.js';
import Home from '../components/Home.js';
import MentorPage from "./Mentors.js";
import Logout from "./Logout.js";
import PrivateRoute from "./PrivateRoute.js";
import RecruitmentTracker from '../components/RecruitmentTracker.js';
import InvalidUser from "./InvalidUser.js";
import RequesterProfile from "./RequesterProfile.js";
import CustomerOptIn from "./CustomerOptIn.js";
import Feedback from "./Feedback.js";
import AuthContext from "../contexts/AuthContext.js"
class App extends Component {
    static contextType = AuthContext
    constructor(props) {
        super(props)
        this.state = {
            backgroundUrl: ''
        }
    }

    async componentDidMount() {
    }

    state = {}

    render() {
        return (
            <div className="App">
                {["/login", "/invalid_user", "/"].includes(this.props.location.pathname) || this.props.location.pathname.includes("/requester_profile") || this.props.location.pathname.includes("/opt_in") ? "" :
                    <NavBar currentUser={this.context.currentUser}/>}
                <Switch>
                    <PrivateRoute exact path='/' component={Home}/>
                    <PrivateRoute exact path='/network' component={MentorPage}/>
                    <PrivateRoute exact path='/recruitment' component={Recruitment}/>
                    <PrivateRoute exact path='/recruitment_tracker' component={RecruitmentTracker}/>
                    <PrivateRoute exact path='/feedback' component={Feedback}/>
                    <Route exact path='/invalid_user' component={InvalidUser}/>
                    <Route path='/requester_profile/:rec_id' component={RequesterProfile}/>
                    <Route path='/opt_in/:rec_id' component={CustomerOptIn}/>
                    <Route exact path='/login' component={Login}/>
                    <Route exact path='/logout' component={Logout}/>
                    <Route render={function () {
                        return <p>Not found</p>
                    }}/>
                </Switch>
            </div>

        );
    }
}

export default withRouter(App)