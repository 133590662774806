import {Component} from "react";
import {auth} from "../firebase/firebase.utils"
import bsv_white from '../bsv_white.png';
import { Row, Col } from "react-bootstrap";
import background from '../background.png';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

class InvalidUser extends Component {
    componentDidMount() {
        console.log(auth.currentUser)
        if (auth.currentUser !== null)
        {
            auth.signOut()
        }
    }

    render() {

        return (
            <div className="LoginPage" style={{ backgroundImage: "url(" + background + ")", backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            <Row>
                <Container className="loginLogo" style={{paddingTop:'35px', textAlign:'left', width:'962px'}}>
                    <a href="https://www.basisset.ventures/">
                        <img
                        src={bsv_white}
                        width="124"
                        alt="BSV logo"/>
                    </a>
                </Container>
            </Row>
            <Row>
                <Container className="text-center">
                    <h3 style={{marginTop:'50px', marginBottom:'40px'}}>Applications to join The Hypergrowth Network are accepted on a 
                    rolling basis, all you need is an MVP to apply. There are no fixed timelines or demo day pressure associated with 
                    the program.</h3>
                    <a href="https://airtable.com/shrfqCikw5sE4RO2A"><Button style={{fontSize: '20px', backgroundColor: '#FBB100', color: 'black', width:'160px', padding:'10px'}}>Apply here!</Button></a>
                </Container>
            </Row>
        </div>
        )
    }
}
export default InvalidUser;